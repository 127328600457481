import * as React from 'react';

function SvgInfo(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.85 36.714a.846.846 0 01-.857.857h-12a.846.846 0 01-.857-.857V32.43c0-.483.375-.858.857-.858h2.572V23h-2.572a.846.846 0 01-.857-.857v-4.286c0-.482.375-.857.857-.857h8.572c.482 0 .857.375.857.857v13.714h2.571c.482 0 .857.375.857.858v4.285zm-3.428-24a.846.846 0 01-.857.857h-5.143a.846.846 0 01-.857-.857V8.43c0-.483.375-.858.857-.858h5.143c.482 0 .857.375.857.858v4.285zm17.143 11.143c0-11.357-9.215-20.571-20.572-20.571S3.422 12.5 3.422 23.857c0 11.357 9.214 20.572 20.571 20.572 11.357 0 20.572-9.215 20.572-20.572z"
        fill="#7DCFF5"
      />
    </svg>
  );
}

export default SvgInfo;
